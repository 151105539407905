/*@jsxRuntime classic @jsx React.createElement @jsxFrag React.Fragment*/
import {useMDXComponents as _provideComponents} from "@mdx-js/react";
import React from "react";
function _createMdxContent(props) {
  const _components = Object.assign({
    h1: "h1",
    p: "p"
  }, _provideComponents(), props.components);
  return React.createElement(React.Fragment, null, React.createElement(_components.h1, null, "Thealby Play Area"), "\n", React.createElement("div", {
    className: "w-full md:w-2/5 md:pl-6 mb-4 md:mb-0 text-right p-4 z-40 md:ml-6 bg-gray-700 rounded-r-lg md:rounded-r-none rounded-l-lg text-white inverse float-right inline-block inset-0"
  }, React.createElement("div", {
    className: "mapouter",
    style: {
      clear: 'both',
      float: 'right',
      marginBottom: '1.25em'
    }
  }, React.createElement("div", {
    className: "gmap_canvas"
  }, React.createElement("iframe", {
    width: "300",
    height: "300",
    id: "gmap_canvas",
    src: "https://maps.google.com/maps?q=thealby%20park,%20thealby&t=&z=17&ie=UTF8&iwloc=&output=embed",
    frameBorder: "0",
    scrolling: "no",
    marginHeight: "0",
    marginWidth: "0"
  })))), "\n", React.createElement(_components.p, null, "The play area can be found in the small hamlet of Thealby. The Parish Council leases the land from Normanby Estates and arranged for the play area to be installed. In 2018, a grant was awarded from North Lincolnshire Council for a fully accessible roundabout, benches and picnic tables. This is the only facility available for children in the hamlet."));
}
function MDXContent(props = {}) {
  const {wrapper: MDXLayout} = Object.assign({}, _provideComponents(), props.components);
  return MDXLayout ? React.createElement(MDXLayout, props, React.createElement(_createMdxContent, props)) : _createMdxContent(props);
}
export default MDXContent;
